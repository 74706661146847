"use strict";

jQuery(document).ready(function ($) {
  $('.js-TestimonialCarousel').owlCarousel({
    loop: true,
    nav: false,
    dots: false,
    margin: 0,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 2
      },
      992: {
        items: 3
      }
    }
  });
});